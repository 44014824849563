import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_BOOKING_SERVICE, BOOKING_ROOMS_URL } from '../../endpoints';
import { Method } from '../../types';
import { BookingServiceRoomsApiProps } from './booking-service-booking.types';

export const bookingServiceApi = createApi({
  reducerPath: 'bookingServiceApi',
  tagTypes: ['bookingService'],
  baseQuery: baseQueryWithAuth(BASE_URL_BOOKING_SERVICE),
  endpoints: builder => ({
    getRoomsBookingData: builder.query<number[], BookingServiceRoomsApiProps>({
      query: params => ({
        url: BOOKING_ROOMS_URL,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetRoomsBookingDataQuery } = bookingServiceApi;
