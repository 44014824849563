import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BASE_URL_PAYMENT_SERVICE,
  INIT_WITH_PAYMENT_URL,
} from 'app/providers/store/endpoints';
import { Method } from 'app/providers/store/types';
import { baseQueryWithAuth } from '../helpers';
import { InitWithResponse } from './payment.types';

export const paymentServiceApi = createApi({
  reducerPath: 'paymentServiceApi',
  tagTypes: ['paymentService'],
  baseQuery: baseQueryWithAuth(BASE_URL_PAYMENT_SERVICE),
  endpoints: builder => ({
    postInitWithPayment: builder.mutation<InitWithResponse, { bookingId: string, guestId: number, orderId: string, successUrl: string }>({
      query: body => ({
        url: INIT_WITH_PAYMENT_URL,
        method: Method.Post,
        body: body,
      }),
    }),
  }),
});

export const { usePostInitWithPaymentMutation } = paymentServiceApi;
