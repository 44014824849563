import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../helpers';
import { BASE_URL_ORDER_SERVICE, ORDER_GET_BY_ID } from '../../endpoints';
import { Method } from '../../types';
import { OrderGetByIdResponse } from './order-service.types';

export const orderServiceApi = createApi({
  reducerPath: 'orderServiceApi',
  tagTypes: ['orderService'],
  baseQuery: baseQueryWithAuth(BASE_URL_ORDER_SERVICE),
  endpoints: builder => ({
    getOrderGetById: builder.query<OrderGetByIdResponse, { OrderId: string }>({
      query: params => ({
        url: ORDER_GET_BY_ID,
        method: Method.Get,
        params: params,
      }),
      keepUnusedDataFor: 0.001,
    }),
  }),
});

export const { useGetOrderGetByIdQuery } = orderServiceApi;
